<template>
  <div class="dp-taste-tag" :class="typeClass">
    {{ ProRecipeTasteType.getTypeLabel(typeId) }}
  </div>
</template>

<script>
import { ProRecipeTasteType } from '@/constants/enums';
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    typeId: {
      type: String,
      default: 'A'
    }
  },

  setup: (props) => {
    const blockName = 'dp-taste-tag';
    const typeClass = computed(
      () => blockName + '--' + ProRecipeTasteType.getTypeName(props.typeId)
    );

    return {
      ProRecipeTasteType,
      typeClass
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-taste-tag {
  --color-balance: #666;
  --color-mild: #735e3f;
  --color-rich: #1a1714;
  --color-tea: #962029;
  --color-green-tea: #95990f;

  display: grid;
  place-items: center;
  padding: 7px 5px;
  height: calc(1em + 14px);
  color: variables.$white01;
  font-size: 10px;
  line-height: 1;
  font-weight: normal;
  width: calc(8em + 10px);

  &--balance {
    background-color: var(--color-balance);
  }

  &--mild-and {
    &-bitter,
    &-acidity {
      background-color: var(--color-mild);
    }
  }

  &--rich-and {
    &-bitter,
    &-acidity {
      background-color: var(--color-rich);
    }
  }

  &--tea {
    background-color: var(--color-tea);
  }

  &--green-tea {
    background-color: var(--color-green-tea);
  }
}
</style>
