import { render, staticRenderFns } from "./CoffeeChannelCard.vue?vue&type=template&id=6b50f98d&"
import script from "./CoffeeChannelCard.vue?vue&type=script&lang=js&"
export * from "./CoffeeChannelCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1001212418/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6b50f98d')) {
      api.createRecord('6b50f98d', component.options)
    } else {
      api.reload('6b50f98d', component.options)
    }
    module.hot.accept("./CoffeeChannelCard.vue?vue&type=template&id=6b50f98d&", function () {
      api.rerender('6b50f98d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/shared/CoffeeChannelCard.vue"
export default component.exports